import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { GetApp } from '@material-ui/icons';
import { Spinner } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { Notification } from '../../components/Notification';
import {
	useAuthState,
	useAuthDispatch,
	handleLogoutUser,
} from '../../context/AuthContext';
import GetHelp from './GetHelp';
import logo from '../../images/logo.png';

export default function NavigationBar() {
	Axios.defaults.withCredentials = true;
	const user = useAuthState();
	const dispatch = useAuthDispatch();
	const [hubsDropdown, setHubsDropdown] = useState([]);
	const [loading, setLoading] = useState(false);

	function getHubs() {
		if (!user.loggedIn) return;
		setLoading(true);
		Axios.post('fetch/hubs')
			.then((res) => {
				if (res.status === 200) {
					setHubsDropdown(res.data.hubCode);
				} else {
					console.log('Error logging out');
				}
			})
			.catch((e) => {
				console.log('Error logging out: ', e);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	const getLogs = (e, id, name) => {
		e.preventDefault();
		setLoading(true);
		const options = {
			method: 'POST',
			url: 'fetch/logs',
			responseType: 'blob',
			data: {
				hubId: id,
				hubName: name,
			},
		};

		Axios(options)
			.then((res) => {
				const link = document.createElement('a');
				link.href = URL.createObjectURL(res.data);
				link.setAttribute(
					'download',
					`Hub ${id} Logs_${dateFormat(
						new Date().toISOString(),
						'yyyy-mm-dd, hh-MM-ss'
					)}.txt`
				);
				document.body.appendChild(link);
				link.click();
			})
			.catch((e) => {
				if (e.response.status === 401) {
					handleLogoutUser(dispatch);
					return;
				}
				let errorMessage;
				if (e.response.status === 404) {
					errorMessage = `${name} logs not found.`;
				} else {
					errorMessage = `Error retrieving logs for ${name}.`;
				}
				Notification('danger', errorMessage);
				if (e.response) {
					console.log(e.response);
				} else if (e.request) {
					console.log('Client never recieved request: ' + e.request);
				} else {
					console.log('Error:' + e.message);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (user.loggedIn && hubsDropdown.length === 0) getHubs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	if (!user.loggedIn) return <></>;

	return (
		<Navbar>
			<div style={{ paddingRight: '1rem' }}>
				<Navbar.Brand className="navbar-code-vault-brand">
					<img
						style={{
							marginBottom: '6px',
							borderRadius: '4px',
						}}
						id="image"
						src={logo}
						width="50"
						height="50"
						alt="logo"
					/>{' '}
					CODE SAVER{' '}
				</Navbar.Brand>
			</div>
			<Nav.Link
				exact
				activeClassName="navbar__link--active"
				className="navbar__link"
				to="/"
				as={NavLink}
			>
				{' '}
				BACKUP{' '}
			</Nav.Link>
			<Nav.Link
				activeClassName="navbar__link--active"
				className="navbar__link"
				to="/restore"
				as={NavLink}
			>
				{' '}
				RESTORE{' '}
			</Nav.Link>

			<NavDropdown
				className="navbar__link-dropdown"
				id="collasible-nav-dropdown"
				style={
					loading
						? { opacity: '0.5' }
						: {
								display: 'flex',
								alignContent: 'center',
						  }
				}
				disabled={loading}
				title={
					<>
						{loading && (
							<Spinner
								animation="border"
								variant="danger"
								size="sm"
								style={{
									marginRight: '10px',
								}}
							/>
						)}
						LOGS
					</>
				}
			>
				{hubsDropdown.map((hub, i) => (
					<NavDropdown.Item
						key={hub.hubId}
						onClick={(e) => {
							getLogs(e, hub.hubId, hub.hubName);
						}}
						style={{
							display: 'flex',
							alignItems: 'center',
							marginRight: '15px',
						}}
						disabled={loading}
					>
						<div>{hub.hubName}</div>{' '}
						<GetApp
							fontSize="small"
							style={{
								marginBottom: '3px',
								marginLeft: 'auto',
							}}
						/>
					</NavDropdown.Item>
				))}
			</NavDropdown>
			<GetHelp />
		</Navbar>
	);
}
