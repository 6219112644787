import React from 'react';
import Axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import { Help, OpenInNew, PowerSettingsNew } from '@material-ui/icons';
import { handleLogoutUser, useAuthDispatch } from '../../context/AuthContext';
import useIFramed from '../../hooks/useIFramed';

export default function GetHelp() {
	const isIFramed = useIFramed();
	const dispatch = useAuthDispatch();

	const logoutUser = () => {
		Axios.delete('auth/logout')
			.then(() => {
				handleLogoutUser(dispatch);
			})
			.catch((e) => {
				handleLogoutUser(dispatch);
			});
	};

	const handleOpenInNew = (url) => {
		window.open(url, '_blank').focus();
	};

	return (
		<Dropdown alignRight style={{ marginLeft: 'auto' }}>
			<Dropdown.Toggle
				variant="Primary"
				style={{
					display: 'flex',
					alignItems: 'center',
					fontSize: '0.875rem',
					letterSpacing: '0.02857em',
				}}
			>
				<Help
					fontSize="small"
					style={{
						marginRight: '5px',
					}}
				/>{' '}
				GET HELP
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item
					style={{
						display: 'flex',
						alignItems: 'center',
						marginRight: '10px',
					}}
					onClick={() =>
						handleOpenInNew(
							'https://help.uberflip.com/hc/en-us/articles/360060510331-Code-Saver-User-Guide'
						)
					}
				>
					Open User Guide{' '}
					<OpenInNew
						fontSize="small"
						style={{ marginLeft: 'auto' }}
					/>
				</Dropdown.Item>
				<Dropdown.Item
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
					onClick={() =>
						handleOpenInNew(
							'https://help.uberflip.com/hc/en-us/requests/new'
						)
					}
				>
					Contact Support{' '}
					<OpenInNew
						fontSize="small"
						style={{
							marginLeft: 'auto',
						}}
					/>
				</Dropdown.Item>
				{!isIFramed && (
					<Dropdown.Item
						style={{
							display: 'flex',
							alignItems: 'center',
							marginRight: '10px',
						}}
						onClick={logoutUser}
					>
						Sign Out
						<PowerSettingsNew
							fontSize="small"
							style={{
								marginLeft: 'auto',
							}}
						/>
					</Dropdown.Item>
				)}
			</Dropdown.Menu>
		</Dropdown>
	);
}
