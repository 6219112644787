import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export const Notification = (type, message) => {
	let duration;

	if (type === 'danger') {
		duration = 30000;
	} else if (type === 'warning') {
		duration = 20000;
	} else if (type === 'success') {
		duration = 10000;
	}

	Store.addNotification({
		message: message
			? `${message}`
			: 'An unexpected issue has occurred. Please refresh your browser and try again.',
		type: `${type}`,
		insert: `bottom`,
		container: 'bottom-right',
		animationIn: ['animate__animated', 'animate__fadeIn'],
		animationOut: ['animate__animated', 'animate__fadeOut'],
		dismiss: {
			duration: duration,
			pauseOnHover: true,
			showIcon: true,
		},
	});
};
