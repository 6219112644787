import React from 'react';
import { Accordion, Card, ListGroup } from 'react-bootstrap';
import BackupCodeBlock from './RestoreCodeBlock';
import ContextAwareToggle from './ContextAwareToggle';

export default function RestoreHub({ hubData }) {
	return (
		<Card>
			<ContextAwareToggle eventKey={hubData}>
				<h4 style={{ marginBottom: '0', marginLeft: '0.25em' }}>
					{hubData.name}
				</h4>
				<div className="hub-header-id">ID: {hubData.hubId}</div>
			</ContextAwareToggle>
			<Accordion.Collapse
				style={{ maxHeight: '65vh', overflowY: 'scroll' }}
				eventKey={hubData}
			>
				<ListGroup variant="flush">
					{Object.entries(hubData.codeBlocks).map((codeBlock) => {
						let formattedCodeBlock = codeBlock[1];
						formattedCodeBlock['fileId'] = codeBlock[0];
						formattedCodeBlock['hubId'] = hubData.hubId;
						return (
							<BackupCodeBlock
								codeBlockData={formattedCodeBlock} //1 to grab values
								key={codeBlock}
							></BackupCodeBlock>
						);
					})}
				</ListGroup>
			</Accordion.Collapse>
		</Card>
	);
}
