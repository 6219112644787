import React, { useState } from 'react';
import initialState from './initialState';
import axios from 'axios';

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

export function useAuthState() {
	const context = React.useContext(AuthStateContext);
	if (context === undefined) {
		throw new Error('useAuthState must be used within an AuthProvider');
	}

	return context;
}

export function useAuthDispatch() {
	const context = React.useContext(AuthDispatchContext);
	if (context === undefined) {
		throw new Error('useAuthDispatch must be used within an AuthProvider');
	}

	return context;
}

export async function handleLogoutUser(dispatch) {
	await axios.get('/api/saml/clear-cookie');
	localStorage.removeItem('currentUser');
	dispatch({ loggedIn: false });
}

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(initialState);

	return (
		<AuthStateContext.Provider value={user}>
			<AuthDispatchContext.Provider value={setUser}>
				{children}
			</AuthDispatchContext.Provider>
		</AuthStateContext.Provider>
	);
};
