const userData = localStorage.getItem('currentUser')
	? JSON.parse(localStorage.getItem('currentUser'))
	: null;

const initialState = {
	accountId: userData?.accountId || null,
	userId: userData?.userId || null,
	loggedIn: userData?.loggedIn || false,
};

export default initialState;
