import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup, Button, Modal } from 'react-bootstrap';
import { Warning } from '@material-ui/icons/';

const HubTileModal = ({
	displayModal,
	setDisplayModal,
	modalFunction,
	name,
}) => {
	const placeholder = `Type pause`;
	const [inputValue, setInputValue] = useState('');
	const [disabledButton, setDisabledButton] = useState(true);
	const handleClose = () => setDisplayModal(false);

	let url;

	return (
		<>
			<Modal show={displayModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Confirm Pausing Auto-Backup</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<div style={{ marginRight: '10px' }}>
							<Warning
								fontSize="large"
								style={{ color: 'red' }}
							></Warning>
						</div>
						<div>
							Pausing will disable automatic backups for{' '}
							<b>{name}</b>. This will not delete any existing
							backup.
						</div>
					</div>

					<br />
					<div>
						Type{' '}
						<a
							style={{ color: '#212529', paddingRight: '5px' }}
							href={url}
							rel="noopener noreferrer"
							target="_blank"
						>
							<b>pause</b>
						</a>
						in the box below to confirm.
					</div>
				</Modal.Body>

				<div
					className="input-group mb-3"
					style={{ paddingLeft: '20px', paddingRight: '20px' }}
				>
					<InputGroup size="sm" className="mb-3">
						<FormControl
							aria-label="Default"
							aria-describedby="inputGroup-sizing-default"
							type="text"
							value={inputValue}
							placeholder={placeholder}
							onChange={(e) => {
								setInputValue(e.target.value);
								if (e.target.value === 'pause') {
									setDisabledButton(false);
								} else {
									setDisabledButton(true);
								}
							}}
							required
						/>
					</InputGroup>
				</div>

				<Modal.Footer>
					<Button
						className="btn"
						size="sm"
						variant="link"
						onClick={handleClose}
					>
						Cancel
					</Button>
					<Button
						className="btn"
						size="sm"
						variant="danger"
						style={{ backgroundColor: '#CE0058' }}
						onClick={(e) => {
							modalFunction(e);
						}}
						disabled={disabledButton}
					>
						Pause
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

HubTileModal.propTypes = {
	displayModal: PropTypes.bool.isRequired,
	setDisplayModal: PropTypes.func.isRequired,
	modalFunction: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
};

export default HubTileModal;
