import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import Axios from 'axios';
import moment from 'moment';
import { OpenInNew, Pause } from '@material-ui/icons/';
import { Card, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Notification } from './Notification';
import { handleLogoutUser, useAuthDispatch } from '../context/AuthContext';
import HubTileModal from './HubTileModal';
import '../styles/toggle.css';
import '../styles/card.css';

const HubTile = ({
	id,
	name,
	url,
	count,
	vaultExists,
	isVaulted,
	workerCheck,
	showTimer,
}) => {
	const [isVaultedState, setIsVaultedState] = useState(isVaulted);
	const [displayModal, setDisplayModal] = useState(false);
	const [disableToggle, setDisableToggle] = useState(false);
	const [backupDate, setBackupDate] = useState('');
	const [vaultExistsAlias, setVaultExistsAlias] = useState(vaultExists);
	const dispatch = useAuthDispatch();

	useEffect(() => {
		if (workerCheck)
			setBackupDate(
				'Last Backup: ' + moment(workerCheck).local().format('lll')
			);
		else setBackupDate('No backups found');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const vaultBlock = (e) => {
		e.preventDefault();
		setDisableToggle(true);

		if (!vaultExistsAlias) {
			setBackupDate('Backing up...');

			Notification(
				'warning',
				`Hub ${name} backups are being created for the first time. This may take a few minutes...`
			);
		}

		const payload = {
			id,
			name,
			url,
		};

		Axios.post('create/vault', payload)
			.then((res) => {
				setDisableToggle(false);
				Notification('success', res.data.message);
				if (!vaultExistsAlias) {
					setBackupDate(
						'Last backup: ' + moment().local().format('lll')
					);
					setVaultExistsAlias(true);
					showTimer(true);
				}
			})
			.catch((e) => {
				setDisableToggle(false);
				setIsVaultedState(false);
				// showTimer(false);
				if (e.response.status === 401) {
					handleLogoutUser(dispatch);

					return;
				} else {
					Notification('danger', e.response.data.message);
				}
				if (e.response) {
					console.log(e.response);
				} else if (e.request) {
					console.log('Client never recieved request: ' + e.request);
				} else {
					console.log('Error:' + e.message);
				}
			});
	};

	const unvaultBlock = (e) => {
		e.preventDefault();
		setDisplayModal(false);

		const payload = {
			id,
			name,
		};

		Axios.post('update/vault', payload)
			.then((res) => {
				console.log(res);
				setIsVaultedState(false);
				Notification('success', res.data.message);
			})
			.catch((e) => {
				setIsVaultedState(true);
				if (e.response.status === 401) {
					handleLogoutUser(dispatch);
					return;
				} else {
					Notification('danger', e.response.data.message);
				}
				if (e.response) {
					console.log(e.response);
				} else if (e.request) {
					console.log('Client never recieved request: ' + e.request);
				} else {
					console.log('Error:' + e.message);
				}
			});
	};

	return (
		<>
			<Card
				className="hub-cards"
				style={{
					width: '18rem',
					minWidth: '18rem',
					margin: '1rem',
				}}
			>
				<Card.Body style={{ maxWidth: '80%' }}>
					<OverlayTrigger
						delay="1000"
						placement="top"
						overlay={<Tooltip>Toggle automatic backup</Tooltip>}
					>
						<div
							style={{
								padding: '1.25rem',
								position: 'absolute',
								top: '0px',
								right: '0px',
							}}
						>
							<Toggle
								checked={isVaultedState}
								disabled={disableToggle}
								icons={{
									unchecked: (
										<Pause
											style={{
												color: 'white',
											}}
											fontSize="inherit"
										></Pause>
									),
								}}
								onChange={(e) => {
									if (e.target.checked) {
										setIsVaultedState(true);
										vaultBlock(e);
									} else {
										setDisplayModal(true);
									}
								}}
							/>
						</div>
					</OverlayTrigger>
					<Card.Title>
						<a
							style={{
								color: '#212529',
							}}
							href={url}
							rel="noopener noreferrer"
							target="_blank"
						>
							{name}
							<div
								className="icon baseline"
								style={{
									marginLeft: '0.25em',
								}}
							>
								<OpenInNew fontSize="small"></OpenInNew>
							</div>
						</a>
					</Card.Title>

					<Card.Subtitle
						className="mb-2 "
						style={{ fontSize: '14px', color: '#00000076' }}
					>
						Custom Code Blocks: {count}
					</Card.Subtitle>
				</Card.Body>
				<Card.Text
					className="mb-2"
					style={{
						fontSize: '13px',
						padding: '0rem 0rem 0.1rem 1.25rem',
						color: '#00000090',
					}}
				>
					Auto-Backup:
					{isVaultedState ? (
						<b
							style={{
								paddingLeft: '4px',
								color: '#4d4d4d',
							}}
						>
							Enabled
						</b>
					) : (
						<b
							style={{
								paddingLeft: '4px',
								color: '#4d4d4d',
							}}
						>
							Paused
						</b>
					)}
				</Card.Text>
				<Card.Footer
					className="text-muted"
					style={{
						fontSize: '12px',
						color: '#00000090',
					}}
				>
					{backupDate}
				</Card.Footer>
			</Card>
			<Spinner></Spinner>

			{displayModal ? (
				<HubTileModal
					displayModal={displayModal}
					setDisplayModal={setDisplayModal}
					modalFunction={unvaultBlock}
					name={name}
				></HubTileModal>
			) : (
				<></>
			)}
		</>
	);
};

HubTile.propTypes = {
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	count: PropTypes.number.isRequired,
	isVaulted: PropTypes.bool.isRequired,
	showTimer: PropTypes.func.isRequired,
};

export default HubTile;
